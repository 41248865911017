// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: true,
  version: env['npm_package_version'],
  defaultLanguage: 'en',
  versionLanguageEn: 'https://el-pixel.com',
  versionLanguageRu: 'https://el-pixel.by',
  versionLanguagePl: 'https://el-pixel.pl',
  supportedLanguages: ['en'],
  mailServiceUrl: 'https://static.el-pixel.com/mail/send_email/',
  staticUrl: 'https://static2.el-pixel.com',
  pixelUrl: 'https://be.el-pixel.com/graphql',
  ga: 'G-Z1054G5SYZ',
  firebase: {
    apiKey: 'AIzaSyDZ3UxC88cUZ6DTN9LZlN_i5o9HI0t80Dc',
    authDomain: 'el-pixel.firebaseapp.com',
    databaseURL: 'https://el-pixel-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'el-pixel',
    storageBucket: 'el-pixel.appspot.com',
    messagingSenderId: '1069351388367',
    appId: '1:1069351388367:web:fe244b41059387c446a3ba',
    measurementId: 'G-WLFFRGTCPE',
  },
};
